import { AddressElement, Elements } from '@stripe/react-stripe-js';
import type { StripeElementsOptions } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
    useCallback, useEffect, useRef, useState
} from 'react';
import Select from 'react-dropdown-select';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { supremeConfig } from '@/api/config';
import { getDevice } from '@/api/device';
import { errorToString } from '@/api/error';
import { PromotionApi, SubscriptionApi } from '@/api/supreme-api-sdk/src';
import type { ButtonRef } from '@/components/Button';
import { Button } from '@/components/Button';
import type { ComponentSectionsPartnersForm } from '@/generated/graphql-types';
import { userApiClient } from '@/pages/_app';
import { useStore } from '@/stores/RootStore';
import { Logo } from '@/templates/Logo';
import { redirectionTargetToUrl } from '@/utils/redirect';
import { StripeOptionsSupreme } from '@/utils/Stripe';

import type { CardRef } from './card';
import Card from './card';
import styles from './PartnersForm.module.scss';

type IPartnersFormProps = {
    data: ComponentSectionsPartnersForm;
};

type Inputs = {
    accessCode: string;
    partnerOrSchool: string;
    email: string;
    password: string;
    fullName: string;
    address: string;
    addressTwo: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
};

const variants = {
    hidden: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0 },
};

const apiClient = new PromotionApi(supremeConfig);
const susbcriptionApiClient = new SubscriptionApi(supremeConfig);

type DdListType = { label: string; value: string; }[];

const PartnersForm = observer((props: IPartnersFormProps) => {
    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        getValues,
        formState: { errors },
    } = useForm<Inputs>({
        mode: 'onTouched'
    });
    const buttonRef = useRef<ButtonRef>(null);
    const router = useRouter();
    const [clientSecret, setClientSecret] = useState<string>();
    const cardRef = useRef<CardRef>(null);

    const [partners, setPartners] = useState<DdListType>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [useIsLoggedIn, setUserLoggedIn] = useState<string | null>(null);
    const [paymentElementComplete, setPaymentElementComplete] = useState<boolean>(false);
    const [addressComplete, setAddressComplete] = useState<boolean>(false);

    const [currentStep, setCurrentStep] = useState<number>(1);
    const [platform, setPlatform] = useState<'supreme' | 'create'>('supreme');
    const [prepaid, setPrepaid] = useState(false);
    const [length, setLength] = useState(30);

    const redirectToSignup = () => {
        router.push(`/partner-signup-new?redirect=${window.location.href}`);
    };

    useEffect(() => {
        susbcriptionApiClient.getSetupIntent(platform).then((data) => {
            setClientSecret(data.data.intent);
            return null;
        }).catch(() => {

        });
    }, [platform]);

    const { layoutStore: { accent, isMobile } } = useStore();

    useEffect(() => {
        // Set partners
        apiClient.promotionGetPartners().then((partnersRes) => {
            partnersRes && partnersRes.data && setPartners(partnersRes.data.filter((partner) => partner.name !== '').map((partner) => ({ label: `${partner.type} - ${partner.name}`, value: `${partner.type} - ${partner.name}` })));
        });

        // Get Logged In User
        userApiClient.getMe().then((res) => {
            if (res && res.data && res.data.user) {
                setUserLoggedIn(res.data.user.email);
            } else {
                redirectToSignup();
            }
        }).catch(() => {
            redirectToSignup();
        });
    }, []);

    const logoutUser = useCallback(() => {
        userApiClient.logout().then(() => {
            setUserLoggedIn(null);
        }).catch(() => null).then(() => {
            redirectToSignup();
        });
    }, []);

    const onSubmit: SubmitHandler<Inputs> = async (data: any) => {
        if (isLoading) { return; }
        setIsLoading(true);
        buttonRef?.current?.startLoading();
        const partnerSplitted = data.partnerOrSchool.split(' - ');

        let body: any = {
            access_code: data.accessCode,
            partner: partnerSplitted[1],
            group: partnerSplitted[0],
            device: await getDevice(),
        };
        if (!prepaid) {
            const result = await cardRef.current?.submit();
            if (result && result.payment_method) {
                body = {
                    access_code: data.accessCode,
                    partner: partnerSplitted[1],
                    group: partnerSplitted[0],
                    city: data.city,
                    country: data.country,
                    line1: data.address,
                    zip: data.zipcode,
                    first_name: data.firstName,
                    last_name: data.lastName,
                    device: await getDevice(),
                    line2: data.addressTwo,
                    state: data.state,
                    payment_method: result.payment_method as string
                };
            } else {
                setIsLoading(false);
                buttonRef?.current?.stopLoading();
                return;
            }
        }
        try {
            await apiClient.promotionSignupExisting(body);
            buttonRef?.current?.stopLoadingWithSuccess();
            if (platform === 'create') {
                window.location.replace(redirectionTargetToUrl('Create'));
            } else {
                window.location.replace(redirectionTargetToUrl('Supreme'));
            }
            setIsLoading(false);
        } catch (e) {
            buttonRef?.current?.stopLoading();
            toast.error(await errorToString(e), {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: 'dark',
            });
            setIsLoading(false);
        }
    };

    const next = () => {
        if (currentStep === 2) {
            buttonRef?.current?.startLoading();
            const data = getValues();
            const partnerSplitted = data.partnerOrSchool.split(' - ');
            apiClient.promotionCheckCode(data.accessCode, partnerSplitted[1]!, partnerSplitted[0]!).then((value) => {
                if (value.data.available) {
                    if (value.data.platform) {
                        setPlatform(value.data.platform as unknown as 'supreme');
                    }
                    if (value.data.prepaid) {
                        setPrepaid(true);
                    } else {
                        setPrepaid(false);
                    }
                    setLength(value.data.length || 30);
                    if (value.data.prepaid) {
                        setCurrentStep(4);
                    } else {
                        setCurrentStep(3);
                    }
                } else {
                    toast.error('This access code is not valid',);
                }
                buttonRef?.current?.stopLoading();
            });
        } else if (currentStep < 4) setCurrentStep(currentStep + 1);
    };

    if (!useIsLoggedIn || !clientSecret) {
        return null;
    }

    return (
        <div className={classNames('flex bg-[#101010] flex-col min-h-screen items-center justify-start overflow-x-hidden md:pt-[13.8rem] pt-[10rem] ', styles.wrap)}>
            <div
                className={
                    'header fixed inset-x-0 top-0 z-10 flex flex-row justify-center bg-[#101010] p-8 md:p-[3.2rem] '
                }
            >
                <Link href="/">
                    <a>
                        <Logo width={141} height={30} showText={true} />
                    </a>
                </Link>
            </div>

            <div
                className={styles.main}
            >

                <div
                    className={classNames(
                        styles.formWrapper
                    )}
                >
                    <Elements
                        stripe={loadStripe(process.env.NEXT_PUBLIC_STRIPE_SUPREME_PUBLISH_KEY!)}
                        options={
                            { ...(StripeOptionsSupreme), clientSecret } as StripeElementsOptions
                        }
                    >
                        <form
                            autoComplete={'off'}
                            onSubmit={handleSubmit(onSubmit)}
                            className={classNames(
                                styles.mainFormClass,
                            )}
                        >
                            <div className='w-full max-w-[36rem]'>
                                <div className='mb-[2rem]'>
                                    <span className='text-[1.4rem] font-[500] opacity-50'>{currentStep}/4</span>
                                    <div className='relative mt-[0.8rem] h-[0.4rem] w-full rounded-[2rem] bg-[#1F1F1F]'>
                                        <motion.div className='absolute inset-y-0 left-0 h-[0.4rem] bg-[#49A147]' animate={{ width: `${currentStep * 25}%` }} />
                                    </div>
                                </div>
                                <span
                                    className={classNames('text-[2.4rem] leading-[2.4rem] mb-[1.6rem] block')}
                                >
                                    {currentStep === 1 && 'We just need a few details to get you started.'}
                                    {currentStep === 2 && 'Enter your partner access code'}
                                    {currentStep === 3 && 'Let’s finalize your account with the information below.'}
                                    {currentStep === 4 && !prepaid && platform === 'supreme' && `Once we have payment info on file, you’ll have ${length} month${length > 1 ? 's' : ''} of DJ-ready music for free!`}
                                    {currentStep === 4 && prepaid && platform === 'supreme' && `Once you sign up, you’ll have ${length} month${length > 1 ? 's' : ''} of DJ-ready music for free!`}
                                    {currentStep === 4 && !prepaid && platform === 'create' && `Once we have payment info on file, you’ll have ${length} month${length > 1 ? 's' : ''} of samples for free!`}
                                    {currentStep === 4 && prepaid && platform === 'create' && `Once you sign up, you’ll have ${length} month${length > 1 ? 's' : ''} of samples for free!`}
                                </span>
                                {currentStep === 2 && <span
                                    className={
                                        'mb-[3.2rem] mt-[1.4rem] block text-[1.4rem] leading-[2rem] text-bpm-white opacity-50'
                                    }
                                >
                                    Please enter the access code you received from one of our partners to access your promotional pricing.
                                </span>}
                                <div>
                                    {currentStep === 1 && <motion.div
                                        initial={{ x: -10, opacity: 0 }}
                                        animate={{ x: 0, opacity: 1 }}
                                        exit={{ x: -10, opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        <span className='mt-[4rem] block text-[2rem] leading-[2.4rem] text-[#8A8C8B]'>Logged in as <span className='text-[#8A8C8B] underline'>{useIsLoggedIn}</span><br />Not you? <a className='text-[#8A8C8B] underline' onClick={() => logoutUser()} href="#">Logout</a> </span>
                                    </motion.div>}
                                    {currentStep === 2 && <motion.div
                                        initial={{ x: -10, opacity: 0 }}
                                        animate={{ x: 0, opacity: 1 }}
                                        exit={{ x: -10, opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        <span className='mb-[1.8rem] block'>Partnership Info</span>
                                        <div className={styles.group}>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className={styles.input}
                                                placeholder={'Access Code'}
                                                id={'accessCode'}
                                                {...register('accessCode', {
                                                    required: true,
                                                })}
                                            />
                                            <img className='absolute left-[1.4rem] top-[1.1rem] h-[2.4rem] w-[2.4rem]' alt="" src="/assets/icons/user_icon.svg" />
                                            <AnimatePresence>
                                                {errors.accessCode && (
                                                    <motion.span
                                                        variants={variants}
                                                        initial="hidden"
                                                        transition={{ duration: '0.5' }}
                                                        animate="enter"
                                                        exit="exit"
                                                        className={'mt-2 block text-bpm-supreme'}
                                                    >
                                                        This field is required
                                                    </motion.span>
                                                )}
                                            </AnimatePresence>
                                        </div>

                                        <div className={classNames(styles.group, styles.ddGruop)}>
                                            <Select
                                                {...register('partnerOrSchool', {
                                                    required: true,
                                                })}
                                                values={[]}
                                                placeholder='Partner or School'
                                                className={styles.input}
                                                style={{
                                                    borderBottom: '1px solid rgb(230, 240, 233, 0.2)', paddingLeft: '4.5rem', opacity: 1
                                                }}
                                                options={partners}
                                                onChange={(values: any) => {
                                                    if (values && values.length > 0) {
                                                        setValue('partnerOrSchool', values[0]?.value!);
                                                        clearErrors('partnerOrSchool');
                                                    }
                                                }}
                                            />
                                            <img className='absolute left-[1.4rem] top-[1.1rem] h-[2.4rem] w-[2.4rem]' alt="" src="/assets/icons/emvelope_icon.svg" />
                                            <AnimatePresence>
                                                {errors.partnerOrSchool && (
                                                    <motion.span
                                                        variants={variants}
                                                        initial="hidden"
                                                        transition={{ duration: '0.5' }}
                                                        animate="enter"
                                                        exit="exit"
                                                        className={'mt-2 block text-bpm-supreme'}
                                                    >
                                                        This field is required
                                                    </motion.span>
                                                )}
                                            </AnimatePresence>
                                        </div>
                                    </motion.div>}
                                    {currentStep === 3 && <motion.div
                                        initial={{ x: -10, opacity: 0 }}
                                        animate={{ x: 0, opacity: 1 }}
                                        exit={{ x: -10, opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        <span className='mb-[1.8rem] block'>Membership Info</span>
                                        <AddressElement
                                            options={{
                                                contacts: [],
                                                mode: 'billing',
                                                display: {}
                                            }}
                                            onChange={(event) => {
                                                setAddressComplete(event.complete);
                                                if (!event.complete) {
                                                    return;
                                                }
                                                setValue('address', event.value.address.line1);
                                                setValue('addressTwo', event.value.address.line2 || '');
                                                setValue('city', event.value.address.city);
                                                setValue('country', event.value.address.country);
                                                setValue('state', event.value.address.state);
                                                setValue('zipcode', event.value.address.postal_code);
                                            }} />
                                    </motion.div>}
                                    {currentStep === 4 && !prepaid && <motion.div
                                        initial={{ x: -10, opacity: 0 }}
                                        animate={{ x: 0, opacity: 1 }}
                                        exit={{ x: -10, opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        <span className='mb-[1.8rem] block'>Payment Info</span>
                                        <Card
                                            ref={cardRef}
                                            onChange={(e) => {
                                                setPaymentElementComplete(e.complete);
                                            }} />

                                        <span
                                            className={
                                                'mb-[3.2rem] mt-[1.4rem] block text-[1.4rem] leading-[2rem] text-bpm-white opacity-50'
                                            }
                                        >
                                            No subscription fee will be charged at this point. Your first payment will be taken out once your trial period ends. Please see <Link href="/terms">Terms & Conditions</Link> for details.
                                        </span>
                                    </motion.div>}
                                    {currentStep === 4 && prepaid && <motion.div
                                        initial={{ x: -10, opacity: 0 }}
                                        animate={{ x: 0, opacity: 1 }}
                                        exit={{ x: -10, opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        <span className='mb-[1.8rem] block'>Subscription Info</span>
                                        <span
                                            className={
                                                'mb-[3.2rem] mt-[1.4rem] block text-[1.4rem] leading-[2rem] text-bpm-white opacity-50'
                                            }
                                        >
                                            Your access code is eligible for a {length} month free {platform.substring(0, 1).toUpperCase()}{platform.slice(1)} subscription.
                                            After the discount ends, your account will be downgraded to a free trial.
                                        </span>
                                        <span
                                            className={
                                                'mb-[3.2rem] mt-[1.4rem] block text-[1.4rem] leading-[2rem] text-bpm-white opacity-50'
                                            }
                                        >
                                            No subscription fee will be charged at this point. Please see <Link href="/terms">Terms & Conditions</Link> for details.
                                        </span>
                                    </motion.div>}
                                </div>
                                <Button
                                    ref={buttonRef}
                                    animated
                                    disabled={currentStep === 3 ? !addressComplete : currentStep !== 4 && (prepaid || paymentElementComplete)}
                                    className={'mt-8 w-full'}
                                    style={accent ? { background: accent } : {}}
                                    onClick={() => (currentStep !== 4 ? next() : handleSubmit(onSubmit)())}
                                >
                                    {currentStep === 4 ? 'Sign Up' : 'Continue'}
                                </Button>
                            </div>

                        </form >
                    </Elements>
                    <div className={styles.imageWrapper}>
                        <img
                            className={styles.mainImage}
                            alt=''
                            src={isMobile ? props.data.MobileImage?.data?.attributes?.url : props.data.MainImage?.data?.attributes?.url}
                        />
                    </div>
                </div >
            </div >
            <div className='mx-auto mt-[6.9rem] mb-[3.2rem] max-w-[32.8rem] text-center text-[1.4rem] text-bpm-white opacity-50 md:mb-[7.2rem] md:mt-[7.6rem] md:text-left'>By proceeding you acknowledge that you have read, understood and agree to our <Link href="/terms"><a>Terms and Conditions</a></Link>.</div>
        </div >
    );
});

export { PartnersForm };
