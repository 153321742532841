export enum TrackingTarget {
    gtag = 'gtag',
    fbq = 'fbq',
}

export enum EventType {
    signUpStep = 'sign_up_step',
}

interface Options {
    type: EventType;
}

export enum SignUpStep {
    landingPage = 'landing-page'
}

interface SubscriptionData {
    promo_code?: string;
}

export interface SignUpOptions extends Options {
    type: EventType.signUpStep;
    data: SubscriptionData & {
        step: SignUpStep;
        platform?: string,
    };
}

type AnyOptions = SignUpOptions;

export function fbqTrack(options: { type: string; data?: object }) {
    if ((window as any).fbq) {
        try {
            (window as any).fbq('track', options.type, {
                ...options.data,
            });
            // eslint-disable-next-line no-empty
        } catch (error) {}
    }
}

export function gtagEvent(options: AnyOptions) {
    if ((window as any).gtag) {
        try {
            (window as any).gtag('event', options.type, {
                send_to: process.env.NEXT_PUBLIC_GA4_ID,
                ...options.data,
            });
            // eslint-disable-next-line no-empty
        } catch (error) {}
    }
}

export function trackEvent(options: AnyOptions, targets: TrackingTarget[] = [TrackingTarget.fbq, TrackingTarget.gtag]) {
    if (targets.indexOf(TrackingTarget.gtag) !== -1) gtagEvent(options);
    if (targets.indexOf(TrackingTarget.fbq) !== -1) fbqTrack(options);
}
